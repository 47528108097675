<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('nav.talqah_business.orders.title')"
        :btn-title="$t('talqah_business.orders.new_btn')"
        :route-name="'talqah-business-orders-create'"
        :paths="pagePath()"
    />

<!--    <div>-->
<!--      <filter-header-->
<!--          :content="content"-->
<!--          @applyFilter="applyFilter"-->
<!--          @resetFilter="resetFilter"-->
<!--          @orderApplicants="orderApplicants"-->
<!--      />-->
<!--    </div>-->
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1">

        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getData"
                :fields="fields"
                :custimized-items="[
                { name: 'ref_id' },
                { name: 'provider_name' },
                { name: 'delivery_to' },
                { name: 'date' },
                { name: 'duration' },
                { name: 'total_cost' },
                { name: 'is_paid' },
                { name: 'notes' },
                { name: 'vendor' },
                { name: 'service_type' },
                { name: 'action' },
                { name: 'price' },
                ...statusColumns.map(s => ({ name: s.key })),
                { name: 'status' },
                { name: 'rate' },
              ]"
            >
              <template slot="ref_id" slot-scope="{ data }">
                <span>{{ data.item.reference_id }}</span>
              </template>
              <template slot="provider_name" slot-scope="{ data }">
                <span>{{ data.item.provider.name }} ({{ data.item.provider.user.phone }}) - ({{ data.item.provider.orders_count }}) </span>
              </template>
              <template slot="delivery_to" slot-scope="{ data }">
                <div class="">
                  <div class="text-font-sub " style="font-size:14px">{{ data.item.customer_info.address }} - {{ data.item.customer_address_desc }}</div>

                  <a v-if="data.item.to_lat && data.item.to_lng" class="value link text-main" :href="geolocationLink(`${data.item.to_lng},${data.item.to_lat}`)"
                     target="_blank">{{ $t('users.table.show_addresses') }}</a>
                </div>
              </template>
              <template v-for="status in statusColumns" :slot="status.key" slot-scope="{ data }">
                <div class="d-flex justify-content-center align-items-center" style="height: 50px">
                  <div
                    class="status-circle"
                    :text="''"
                    :class="{
              'filled': data.value.filled,
              'current': data.value.current
            }"
                  ></div>
                </div>
              </template>
              <template slot="delivery_datetime" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">{{ formatDate(data.item.delivery_datetime).replace('-','\n') }}</span>
                </div>
              </template>
              <template slot="duration" slot-scope="{ data }">
                <span v-if="data.item.status_complete !== 2 && data.item.assigned_at != null" style="margin: 0; padding: 5px 10px; border-radius: 10px" :style="{'color': 'white', 'background': '#' + getColorBasedOnTime(getTimePassed(data.item.assigned_at, data.item.completed_at || new Date()))}">{{ data.item.assigned_at ? getTimePassed(data.item.assigned_at, data.item.completed_at || new Date()) : '-' }}</span>
                <!-- <custom-badge v-if="data.item.completed_at" :text="data.item.assigned_at ? getTimePassed(data.item.assigned_at, data.item.completed_at) : '-'" :color="getColorBasedOnTime(getTimePassed(data.item.assigned_at, data.item.completed_at))"/> -->
                <span v-else>-</span>
              </template>

              <template slot="vendor" slot-scope="{ data }">
                <check-value :value="data.item.vendor"/>
              </template>

              <template slot="service_type" slot-scope="{ data }">
                <check-value :value="data.item.service_type"/>
              </template>

              <template slot="total_cost" slot-scope="{ data }">
                <div class="d-flex flex-column">
                  <div>التوصيل: {{ data.item.delivery_fee }}</div>
                  <div>الطلب: {{ data.item.cost }}</div>
                </div>
              </template>

              <template slot="is_paid" slot-scope="{ data }">
                <span v-if="data.item.is_paid">مدفوع</span>
                <span v-else>غير مدفوع</span>
              </template>

              <template slot="notes" slot-scope="{ data }">
                <div class="d-flex flex-column">
                  <div v-if="data.item.provider_notes">الجهة: {{ data.item.provider_notes }}</div>
                  <div v-if="data.item.admin_notes">الأدمن: {{ data.item.admin_notes }}</div>
                </div>
              </template>

<!--              <template slot="price" slot-scope="{ data }">
                <check-price
                    :price="Number(data.item.price)+Number(data.item.service_fee)"
                    :dis-price="Number(data.item.price)+Number(data.item.service_fee)-Number(data.item.dis_price)"
                />
              </template>-->

              <template slot="rate" slot-scope="{ data }">
                <customer-rate :rate="data.item.rate"/>
              </template>

              <template slot="status" slot-scope="{ data }">
                <custom-badge :text="getStatusInfo(data.item.status).name" :color="getStatusInfo(data.item.status).color"/>
              </template>


              <template slot="action" slot-scope="{ data }">
                <router-link :to="toToDetail(data.item.id)">
                  <span class="text-regular-14 text-font-secondary permission-show-detail">
                      <eyeon-icon class="mx-2"/>
<!--                    <vertical-dots-icon/>-->
                    </span>
                </router-link>
              </template>


            </w-tables>
          </div>
        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotalData"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalData.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';

import {WameedPagination, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import CheckPrice from "@/components/checkPrice";
import CustomerRate from "@/components/customerRate";
import StatusBadge from "@/components/statusBadge";
import CustomBadge from "@/components/customBadge";
import CheckValue from "@/components/checkValue";
import StatusCircle from '@/views/pages/talqah_business/orders/components/statusCircle.vue';

export default {
  components: {
    StatusCircle,
    CheckValue,
    CustomBadge,
    StatusBadge,
    CustomerRate,
    CheckPrice,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        status: '',
        year: '',
        month: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      statuses: [
        { name: "قيد المراجعة", value: 'pending', color: 'f0ad4e' }, // Orange
        { name: "جاري الإستلام", value: 'in_recive', color: 'f0ad4e' }, // Orange
        { name: "تم الإستلام بالمخزن", value: 'recieved_in_warehouse', color: '17a2b8' }, // Teal (Info)
        { name: "التأكيد من العميل", value: 'confirmed_by_client', color: 'ffc107' }, // Yellow
        { name: "قيد النقل", value: 'in_transit', color: '007bff' }, // Blue
        { name: "تم استلام الفرع", value: 'recieved_in_branch', color: '6f42c1' }, // Purple
        { name: "الاسناد للموصل", value: 'assigned_to_driver', color: 'fd7e14' }, // Orange-Red
        { name: "جارِ التجهيز", value: 'preparing', color: '20c997' }, // Green-Teal
        { name: "في الطريق إلى التسليم للعميل", value: 'out_for_delivery', color: '28a745' }, // Green
        { name: "مقبول", value: 'approved', color: '5cb85c' }, // Green
        { name: "في الطريق", value: 'in_way', color: '0275d8' }, // Blue
        { name: "ملغي", value: 'canceled', color: 'dc3545' }, // Red
        { name: "معلق", value: 'on_hold', color: 'ffc107' }, // Yellow
        { name: "مرتجع", value: 'refunded', color: '6610f2' }, // Purple
        { name: "تم التوصيل", value: 'completed', color: '6c757d' } // Gray
      ],
      statusColumns: [
        { key: 'pending', value: 'pending', label: 'قيد المراجعة', position: 1 },
        { key: 'in_recive', value: 'in_recive', label: 'جاري الإستلام', position: 2 },
        { key: 'recieved_in_warehouse', value: 'recieved_in_warehouse', label: 'تم الإستلام بالمخزن', position: 3 },
        { key: 'confirmed_by_client', value: 'confirmed_by_client', label: 'التأكيد من العميل', position: 4 },
        { key: 'in_transit', value: 'in_transit', label: 'قيد النقل', position: 5 },
        { key: 'recieved_in_branch', value: 'recieved_in_branch', label: 'تم استلام الفرع', position: 6 },
        { key: 'assigned_to_driver', value: 'assigned_to_driver', label: 'الاسناد للموصل', position: 7 },
        { key: 'preparing', value: 'preparing', label: 'جارِ التجهيز', position: 8 },
        { key: 'out_for_delivery', value: 'out_for_delivery', label: 'في الطريق إلى التسليم للعميل', position: 9 },
        { key: 'in_way', value: 'in_way', label: 'في الطريق', position: 11 },
        { key: 'completed', value: 'completed', label: 'تم التوصيل', position: 12 },
        { key: 'canceled', value: 'canceled', label: 'ملغي', position: 13 },
        { key: 'on_hold', value: 'on_hold', label: 'معلق', position: 14 },
        { key: 'refunded', value: 'refunded', label: 'مرتجع', position: 15 },
      ],
      content: [
        // {
        //   title: this.$i18n.t('orders.table.type'),
        //   key: 'types',
        //   id: 'id',
        //   name: 'title',
        //   selectedData: [],
        //   type: 'text',
        //   translate: true,
        //   data: this.serviceTypes,
        // },
        {
          title: this.$i18n.t('orders.table.status'),
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          type: 'text',
          translate: true,
          data: [
            {
              id: '-1',
              title: this.$i18n.t('status.pending'),
            },
            {
              id: 1,
              title: this.$i18n.t('status.preparing'),
            },
            {
              id: 2,
              title: this.$i18n.t('status.canceled'),
            },
            {
              id: 3,
              title: this.$i18n.t('status.scheduled'),
            },
            {
              id: 4,
              title: this.$i18n.t('status.delivering'),
            },

            {
              id: 5,
              title: this.$i18n.t('status.complete'),
            },
            {
              id: 6,
              title: this.$i18n.t('status.waiting'),
            },
            {
              id: 7,
              title: this.$i18n.t('status.confirmation'),
            },
          ],
        },
        {
          title: this.$i18n.t('orders.table.date'),
          key: "Date",
          id: "id",
          name: "title",
          selectedData: [],
          translate: true,
          type: "date",
          startDate: null,
          endDate: null,
        },
        // {
        //   title: this.$i18n.t('filters.stars'),
        //   key: 'stars',
        //   id: 'id',
        //   name: 'title',
        //   selectedData: [],
        //   type: 'stars',
        //   data: [
        //
        //     {
        //       id: 1,
        //       title: 1,
        //     },
        //     {
        //       id: 2,
        //       title: 2,
        //     },
        //     {
        //       id: 3,
        //       title: 3,
        //     },
        //     {
        //       id: 4,
        //       title: 4,
        //     },
        //     {
        //       id: 5,
        //       title: 5,
        //     },
        //   ]
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/talqahBusiness/orders/getData',
      getTotalData: 'admin/talqahBusiness/orders/getTotal',
      serviceTypes: 'vendor/orders/getServiceTypes',
    }),

    fields() {
      const baseFields = [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'ref_id',
          label: this.$i18n.t('orders.table.no'),
          sortable: true,
        },

        {
          key: 'provider_name',
          label: "اسم الشركة",
          sortable: true,
        },

        {
          key: 'provider_ref_id',
          label: "الرقم المرجعي",
          sortable: true,
        },

        {
          key: 'customer_info.name',
          label: this.$i18n.t('orders.table.user_name'),
          sortable: true,
        },
        {
          key: 'customer_info.phone',
          label: this.$i18n.t('orders.table.user_phone'),
          sortable: true,
        },
        {
          key: 'to_city.name_ar',
          label: 'المدينة',
          sortable: true,
        },

        {
          key: 'delivery_to',
          label: "العنوان",
          sortable: true,
        },

        {
          key: 'status',
          label: this.$i18n.t('orders.table.status'),
          sortable: true,
        },
        {
          key: 'driver_name',
          label: this.$i18n.t('orders.table.driver_name'),
          sortable: true,
        },

        {
          key: 'delivery_datetime',
          label: this.$i18n.t('orders.table.date'),
          sortable: true,
          tdClass: 'w-120px'
        },

        // {
        //   key: 'delivery_fee',
        //   label: this.$i18n.t('orders.table.date'),
        //   sortable: true,
        //   tdClass: 'w-120px'
        // },

        // {
        //   key: 'assigned_at',
        //   label: this.$i18n.t('orders.table.duration'),
        //   sortable: true,
        //   tdClass: 'w-120px'
        // },

        // {
        //   key: 'order_from',
        //   label: "مكان الطلب",
        //   sortable: true,
        //   tdClass: 'w-120px'
        // },
        // {
        //   key: 'vendor',
        //   label: this.$i18n.t('orders.table.place'),
        //   sortable: true,
        // },
        // {
        //   key: 'service_type',
        //   label: this.$i18n.t('orders.table.type'),
        //   sortable: true,
        // },
        {
          key: 'total_cost',
          label: "قيمة الطلب",
          sortable: true,
        },
        {
          key: 'is_paid',
          label: "الدفع",
          sortable: true,
        },
        {
          key: 'notes',
          label: "الملاحظة",
          sortable: true,
        },
        // {
        //   key: 'total_cost',
        //   label: this.$i18n.t('table.price'),
        //   sortable: true,
        // },

        {
          key: 'action',
          label: this.$i18n.t('table.options')
        },
      ];

      const statusFields = this.statusColumns.map(status => ({
        key: status.key,
        label: status.label,
        sortable: true,
        thClass: 'horizontal-header',
        tdClass: 'status-column-cell',
        formatter: (value, key, item) => {
          if (!item || !item.status) return { filled: false, current: false };

          const foundStatus = this.statusColumns.find(s => s.value === item.status);
          const currentPosition = foundStatus ? foundStatus.position : 0;

          return {
            filled: currentPosition >= status.position,
            current: item.status === status.value
          };
        }
      }));
      // Find the index of the 'status' column
      const statusIndex = baseFields.findIndex(field => field.key === 'status');

      // Insert status fields before the 'status' column
      return [
        ...baseFields.slice(0, statusIndex),
        ...statusFields,
        ...baseFields.slice(statusIndex)
      ];
    }
  },

  async created() {
    await this.getServiceTypes();
    this.loadData();
    console.log(this.statusColumns)
    this.content[0].data = this.serviceTypes;
  },

  methods: {
    ...mapActions({
      _loadData: 'admin/talqahBusiness/orders/get',
      getServiceTypes: 'vendor/orders/getServiceTypes',
    }),
    createStatusField(status) {
      return {
        key: status.key,
        label: status.label,
        sortable: true,
        thClass: 'rotated-header',
        tdClass: 'status-column-cell',
        formatter: (value, key, item) => {
          if (!item || !item.status) return { filled: false, current: false };

          const currentStatus = this.statusColumns.find(s => s.value === item.status);
          const currentPos = currentStatus ? currentStatus.position : 0;

          return {
            filled: currentPos >= status.position,
            current: item.status === status.value
          };
        }
      };
    },
    generateStatusFields() {
      return this.statusColumns.map(status => ({
        key: status.key,
        label: status.label,
        sortable: true,
        thClass: 'rotated-header',
        tdClass: 'status-column-cell',
        formatter: (value, key, item) => {
          if (!item || !item.status) return { filled: false, current: false };

          const foundStatus = this.statusColumns.find(s => s.value === item.status);
          const currentPosition = foundStatus ? foundStatus.position : 0;

          return {
            filled: currentPosition >= status.position,
            current: item.status === status.value
          };
        }
      }));
    },
    getStatusInfo(statusValue) {
      return this.statuses.find(status => status.value === statusValue) ||
        { name: statusValue, color: 'FAA61A' }
    },
    pagePath() {
      if (this.path) {
        return this.path
      }
      return [{title: this.$i18n.t('nav.talqah_business.title')}];
    },

    geolocationLink(temp) {
      let location = temp.split(',');
      let locationLat = location[0];
      let locationLng = location[1];
      return `https://www.google.com/maps/place/${locationLat},${locationLng}/@${locationLat},${locationLng},15z`
    },
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this._loadData(this.filterData);
      console.log(this.getData);
    },
    toToDetail(id) {
      return {
        name: 'talqah-business-order-detail',
        params: {lang: this.$i18n.locale, id},
      };
    },
    getTimePassed(createdAt, completedAt) {
      console.log(createdAt);
      console.log(completedAt);
      const createdDate = new Date(createdAt);
      const completedDate = new Date(completedAt);

      const differenceInMs = completedDate - createdDate;

      const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
      const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((differenceInMs % (1000 * 60)) / 1000);

      // Format the time as h:m:s
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
    getColorBasedOnTime(timeString) {
      // Example input: "2:15:30" (h:m:s)
      const timeParts = timeString.split(':');
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1]);
      const totalMinutes = hours * 60 + minutes;

      if (totalMinutes < 30) {
        return '6fbf6f'; // Light Green for less than 30 min
      } else if (totalMinutes >= 30 && totalMinutes <= 44) {
        return 'cdc413'; // Yellow for 30-44 minutes
      } else if (totalMinutes >= 45 && totalMinutes <= 60) {
        return 'af0505'; // Dark Red for 45-60 minutes
      } else if (totalMinutes > 60) {
        return '000000'; // Black for above 60 minutes
      }

      return 'FFFFFF'; // Default color (transparent replaced with white as #FFFFFF)
    },
    applyFilter() {
      this.content.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>

<style>
.horizontal-header {
  writing-mode: sideways-lr;
  text-orientation: mixed;
  height: 120px;
}

.status-column-cell {
  text-align: center;
  vertical-align: middle;
  padding: 8px !important;
}

/* Optional: Add min-width to prevent column collapse */
.status-column {
  min-width: 120px;
}

/* Keep your existing circle styles */
.status-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin: 0 auto;
}

.status-circle.filled {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

.status-circle.current {
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.3);
  transform: scale(1.2);
}
</style>