var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('nav.talqah_business.orders.title'),"btn-title":_vm.$t('talqah_business.orders.new_btn'),"route-name":'talqah-business-orders-create',"paths":_vm.pagePath()}}),_c('section',{staticClass:"\n        wameed-dashboard-page-content_body\n        d-flex\n        flex-column\n        justify-content-between\n        overflow-hidden\n      "},[_c('div',{staticClass:"d-flex flex-wrap flex-1"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[_c('w-tables',{attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"per-page":_vm.filterData.per_page,"page-number":_vm.filterData.page,"items":_vm.getData,"fields":_vm.fields,"custimized-items":[
                { name: 'ref_id' },
                { name: 'provider_name' },
                { name: 'delivery_to' },
                { name: 'date' },
                { name: 'duration' },
                { name: 'total_cost' },
                { name: 'is_paid' },
                { name: 'notes' },
                { name: 'vendor' },
                { name: 'service_type' },
                { name: 'action' },
                { name: 'price' } ].concat( _vm.statusColumns.map(function (s) { return ({ name: s.key }); }),
                [{ name: 'status' }],
                [{ name: 'rate' }] )},scopedSlots:_vm._u([{key:"ref_id",fn:function(ref){
              var data = ref.data;
return [_c('span',[_vm._v(_vm._s(data.item.reference_id))])]}},{key:"provider_name",fn:function(ref){
              var data = ref.data;
return [_c('span',[_vm._v(_vm._s(data.item.provider.name)+" ("+_vm._s(data.item.provider.user.phone)+") - ("+_vm._s(data.item.provider.orders_count)+") ")])]}},{key:"delivery_to",fn:function(ref){
              var data = ref.data;
return [_c('div',{},[_c('div',{staticClass:"text-font-sub ",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(data.item.customer_info.address)+" - "+_vm._s(data.item.customer_address_desc))]),(data.item.to_lat && data.item.to_lng)?_c('a',{staticClass:"value link text-main",attrs:{"href":_vm.geolocationLink(((data.item.to_lng) + "," + (data.item.to_lat))),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('users.table.show_addresses')))]):_vm._e()])]}},_vm._l((_vm.statusColumns),function(status){return {key:status.key,fn:function(ref){
              var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"50px"}},[_c('div',{staticClass:"status-circle",class:{
              'filled': data.value.filled,
              'current': data.value.current
            },attrs:{"text":''}})])]}}}),{key:"delivery_datetime",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(_vm.formatDate(data.item.delivery_datetime).replace('-','\n')))])])]}},{key:"duration",fn:function(ref){
            var data = ref.data;
return [(data.item.status_complete !== 2 && data.item.assigned_at != null)?_c('span',{staticStyle:{"margin":"0","padding":"5px 10px","border-radius":"10px"},style:({'color': 'white', 'background': '#' + _vm.getColorBasedOnTime(_vm.getTimePassed(data.item.assigned_at, data.item.completed_at || new Date()))})},[_vm._v(_vm._s(data.item.assigned_at ? _vm.getTimePassed(data.item.assigned_at, data.item.completed_at || new Date()) : '-'))]):_c('span',[_vm._v("-")])]}},{key:"vendor",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.vendor}})]}},{key:"service_type",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.service_type}})]}},{key:"total_cost",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v("التوصيل: "+_vm._s(data.item.delivery_fee))]),_c('div',[_vm._v("الطلب: "+_vm._s(data.item.cost))])])]}},{key:"is_paid",fn:function(ref){
            var data = ref.data;
return [(data.item.is_paid)?_c('span',[_vm._v("مدفوع")]):_c('span',[_vm._v("غير مدفوع")])]}},{key:"notes",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex flex-column"},[(data.item.provider_notes)?_c('div',[_vm._v("الجهة: "+_vm._s(data.item.provider_notes))]):_vm._e(),(data.item.admin_notes)?_c('div',[_vm._v("الأدمن: "+_vm._s(data.item.admin_notes))]):_vm._e()])]}},{key:"rate",fn:function(ref){
            var data = ref.data;
return [_c('customer-rate',{attrs:{"rate":data.item.rate}})]}},{key:"status",fn:function(ref){
            var data = ref.data;
return [_c('custom-badge',{attrs:{"text":_vm.getStatusInfo(data.item.status).name,"color":_vm.getStatusInfo(data.item.status).color}})]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('router-link',{attrs:{"to":_vm.toToDetail(data.item.id)}},[_c('span',{staticClass:"text-regular-14 text-font-secondary permission-show-detail"},[_c('eyeon-icon',{staticClass:"mx-2"})],1)])]}}],null,true)})],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotalData)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotalData.totalItems,"per_page":_vm.filterData.per_page,"menu-title":_vm.$t('common.show_results')},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }