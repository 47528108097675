<template>
  <div
    class="status-circle"
    :class="{
      'filled': filled,
      'current': current
    }"
    :title="current ? 'Current Status' : ''"
  ></div>
</template>

<script>
export default {
  name: "status-circle",
  props: {
    filled: Boolean,
    current: Boolean
  }
};
</script>

<style scoped>
.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #666;
  display: inline-block;
  transition: all 0.3s ease;
}

.status-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #ddd;
  transition: all 0.3s ease;
}

.status-circle.filled {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

.status-circle.current {
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.3);
  transform: scale(1.2);
}

.rotated-header {
  transform: rotate(-90deg) translateX(-35px);
  transform-origin: left bottom 0;
  white-space: nowrap;
  height: 150px;
  width: 40px;
  padding: 10px 0 !important;
  position: relative;
}

.status-column-cell {
  text-align: center;
  vertical-align: middle;
  height: 50px;
}
</style>